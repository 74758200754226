import React, { useContext } from "react";
import { PurchasesContext } from "../../context/PurchasesContext";
import { ModalContext } from "../../context/ModalContext";
import PurchaseForm from "./PurchaseForm";
import StatusBadge from "../common/StatusBadge";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";
import moment from "moment";

const PurchaseRow = ({ purchase, hideColumns }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const { setPurchase } = useContext(PurchasesContext);

  const handleCancel = () => {
    clearModal();
    setPurchase(null);
  };

  const handleEdit = () => {
    setPurchase(purchase);
    modalComponent(
      "Editar Compra",
      <PurchaseForm
        purchase_id={purchase.purchase_id}
        handleCancel={handleCancel}
      />
    );
  };

  const renderPaymentMethod = () => {
    if (purchase.payment_method && purchase.payment_method !== null) {
      return purchase.payment_method.name;
    }
  };

  const renderExpiration = () => {
    let createdAt = moment(purchase.due_date).utc();
    if (purchase.subscription_id !== null) {
      if (purchase.status === "active") {
        let day = createdAt.day();
        let nextMonth = moment();
        let currDay = moment().day();
        if (day < currDay) {
          nextMonth = moment(nextMonth, "M").add(
            purchase.subscription_interval,
            purchase.subscription_period
          );
        }
        return nextMonth
          .startOf("month")
          .add(day, "days")
          .format("DD MMM YYYY");
      }
      return createdAt
        .add(purchase.subscription_interval, purchase.subscription_period)
        .format("DD MMM YYYY");
    }
    return createdAt
      .add(purchase.expiration_days, "days")
      .format("DD MMMM YYYY");
  };

  const renderCustomer = () => {
    if (
      purchase.customer &&
      purchase.customer !== null &&
      !window.location.pathname.includes("customer") &&
      (!Array.isArray(hideColumns) || !hideColumns.includes("customer"))
    ) {
      return (
        <td>
          <Link to={`/myadmin/customer/${purchase.customer.customer_id}`}>
            <i className="fa fa-eye"></i> {purchase.customer.name}{" "}
            {purchase.customer.last_name}
          </Link>
        </td>
      );
    }
  };

  const renderProduct = () => {
    if (purchase.product && purchase.product !== null) {
      return purchase.product.name;
    }
  };

  const renderCustomerTags = () => {
    if (Array.isArray(purchase?.customer?.tags)) {
      return purchase.customer.tags.map((tag) => {
        return (
          <span key={tag.tag_id} className="badge badge-pill bg-secondary me-1">
            {tag.content}
          </span>
        );
      });
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>
        {purchase.invoice_id ? purchase.invoice_id : purchase.purchase_id}{" "}
      </td>
      {renderCustomer()}
      <td>{renderProduct()}</td>
      <td>{moment(purchase.due_date).utc().format("DD MMM YYYY")}</td>
      <td>{purchase.date !== null ? moment(purchase.date).utc().format("DD MMM YYYY") : "N/D"}</td>
      <td>{formatMonto(purchase.amount)} MXN</td>
      <td>{renderExpiration()}</td>
      <td>{renderPaymentMethod()}</td>
      <td>
        <StatusBadge status={purchase.status} date={purchase.updatedAt} />
      </td>
      <td>
        <button className="btn btn-sm btn-outline-dark" onClick={handleEdit}>
          <i className="fas fa-edit" />
        </button>
      </td>
    </tr>
  );
};

export default PurchaseRow;
