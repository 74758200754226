import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { CustomerContext } from "../../context/CustomerContext";
import FileInput from "../common/FileInput";
import SelectCustomerTags from "./SelectCustomerTags";
import BirthdateInput from "../common/BirthdateInput";
import { getValue } from "../../utils";

const CustomerForm = ({ customer_id }) => {
  const {
    spinner,
    customer,
    getCustomer,
    postCustomer,
    createCustomer,
    saveCustomerTags,
    setPropiedadCustomer,
  } = useContext(CustomerContext);

  const { business_name } = useContext(AppConfigContext);

  useEffect(() => {
    if (isNaN(customer_id)) {
      createCustomer();
    } else {
      getCustomer(customer_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveCustomerTags = async () => {
    if (customer.updatedTags && !isNaN(parseInt(customer_id))) {
      saveCustomerTags({
        ...customer.updatedTags,
        customer_id: Number(customer_id),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleSaveCustomerTags();
    customer.email = String(customer.email).trim();
    postCustomer(customer, handleSaveCustomerTags);
  };

  const renderForm = () => {
    if (customer && customer !== null) {
      return (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 px-0">
              <div className="row mx-auto">
                <div className="col-12 col-lg-6">
                  <FileInput
                    label={"Imagen de Usuario"}
                    value={customer.file}
                    modifierKey={"newFile"}
                    modifier={setPropiedadCustomer}
                    type={"image"}
                  />
                </div>

                <div className="col-12 col-lg-6 px-0">
                  <div className="row mx-auto">
                    <div className="col-6">
                      <label>Nombre</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={customer?.name}
                        onChange={(e) =>
                          setPropiedadCustomer("name", e.target.value)
                        }
                      />
                    </div>

                    <div className="col-6">
                      <label>Apellidos</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={customer?.last_name}
                        onChange={(e) =>
                          setPropiedadCustomer("last_name", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="container-fluid px-2">
                    <div className="px-1">
                      <label>Cumpleaños</label>
                      <BirthdateInput
                        value={getValue(customer, "birthdate")}
                        modifier={(value) =>
                          setPropiedadCustomer("birthdate", value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <label>Correo Electrónico</label>
              <input
                type="text"
                className="form-control mb-3"
                value={customer.email}
                onChange={(e) => setPropiedadCustomer("email", e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>Teléfono</label>
              <input
                type="text"
                className="form-control mb-3"
                value={customer.phone}
                onChange={(e) => setPropiedadCustomer("phone", e.target.value)}
              />
            </div>

            <div className="col-6">
              <label>¿Cómo te enteraste de {business_name}?</label>
              <input
                type="text"
                className="form-control mb-3"
                value={customer.signup_reason}
                onChange={(e) =>
                  setPropiedadCustomer("signup_reason", e.target.value)
                }
              />
            </div>

            <div className="col-6">
              <SelectCustomerTags />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-6 text-right">
              <Link
                to={`/myadmin/customer/${customer_id}`}
                className="btn w-100 text-muted"
              >
                Cancelar
              </Link>
            </div>
            <div className="col-6">
              <button className="btn w-100 btn-primary">
                {spinner ? <div className="spinner-border"></div> : "Guardar"}
              </button>
            </div>
          </div>
        </form>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return <div>{renderForm()}</div>;
};

export default CustomerForm;
